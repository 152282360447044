export default async function getMenuData() {
  return [
    {
      title: 'Dashboard',
      key: 'dashboards',
      icon: 'fe fe-home',
      url: '/dashboard',
    },
    {
      title: 'Users',
      key: 'settings',
      icon: 'fe fe-user',
      url: '/users',
    },
    {
      title: 'Upload Game',
      key: 'upload-games',
      icon: 'fe fe-upload-cloud',
      url: '/games/upload',
    },
    {
      title: 'Games',
      key: 'games',
      icon: 'fe fe-package',
      url: '/games',
    },
    {
      title: 'Groups',
      key: 'groups',
      icon: 'fe fe-users',
      url: '/settings/access',
    },
    {
      title: 'Notifications',
      key: 'notifications',
      icon: 'fe fe-bell',
      url: '/settings/security',
    },
    {
      title: 'Stats',
      key: 'stats',
      icon: 'fe fe-trending-up',
      url: '/settings/security',
    },
  ]
}
