import React from 'react'

import Search from './Search'
import Actions from './Actions'
import UserMenu from './UserMenu'

import style from './style.module.scss'

const TopBar = ({ me }) => {
  return (
    <div className={style.topbar}>
      <div className="mr-auto">
        <Search />
      </div>
      <div className="mr-4 d-none d-sm-block">
        <Actions />
      </div>
      <div className="">
        <UserMenu me={me} />
      </div>
    </div>
  )
}

export default TopBar
