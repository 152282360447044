import React from 'react'
import { Dropdown, Tabs } from 'antd'

import styles from './style.module.scss'

const Actions = () => {
  const menu = (
    <React.Fragment>
      <div className="card cui__utils__shadow width-350 border-0">
        <div className="card-body p-0">
          <div>
            <Tabs className={`${styles.tabs} kit-tabs-bordered`} defaultActiveKey="1">
              <Tabs.TabPane tab="Notifications" key="1">
                <div className="text-center mb-3 py-4 bg-light rounded">No Notifications</div>
              </Tabs.TabPane>
              <Tabs.TabPane tab="Chats" key="2">
                <div className="text-center mb-3 py-4 bg-light rounded">No Chats</div>
              </Tabs.TabPane>
            </Tabs>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
  return (
    <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
      <div className={styles.dropdown}>
        <i className={`${styles.icon} fe fe-bell`} />
      </div>
    </Dropdown>
  )
}

export default Actions
