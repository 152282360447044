// Apollo GraphQL client

// ----------------------------------------------------------------------------
// IMPORTS

/* NPM */
import { createHttpLink, ApolloClient, InMemoryCache, ApolloLink, split } from '@apollo/client'
import { onError } from 'apollo-link-error'
import { WebSocketLink } from 'apollo-link-ws'
import { getMainDefinition } from 'apollo-utilities'
import { SubscriptionClient } from 'subscriptions-transport-ws'

// ----------------------------------------------------------------------------

const createClient = () => {
  const accessToken = localStorage.getItem('accessToken')
  const authorization = accessToken
    ? {
        Authorization: `Bearer ${accessToken}`,
      }
    : undefined
  const httpLink = createHttpLink({
    credentials: 'same-origin',
    headers: {
      'content-type': 'application/json',
      ...authorization,
    },
    uri: process.env.REACT_APP_GRAPHQL,
  })

  return new ApolloClient({
    cache: new InMemoryCache(),
    link: ApolloLink.from([
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
          graphQLErrors.map(({ message, locations, path }) =>
            console.log(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
            ),
          )
        }
        if (networkError) {
          console.log(`[Network error]: ${networkError}`)
        }
      }),

      // Split on HTTP and WebSockets
      process.env.REACT_APP_WS_SUBSCRIPTIONS
        ? split(
            ({ query }) => {
              const definition = getMainDefinition(query)
              return (
                definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
              )
            },
            // Use WebSockets for subscriptions
            new WebSocketLink(
              // Replace http(s) with `ws` for connecting via WebSockts
              new SubscriptionClient(process.env.REACT_APP_GRAPHQL.replace(/^https?/, 'wss'), {
                connectionParams: {
                  headers: {
                    'x-hasura-admin-secret': process.env.REACT_APP_GRAPHQL_KEY,
                  },
                  reconnect: true, // <-- automatically redirect as needed
                },
              }),
            ),
            // ... fall-back to HTTP for everything else
            httpLink,
          )
        : httpLink, // <-- just use HTTP on the server
    ]),
    // On the server, enable SSR mode
    ssrMode: false,
  })
}

export default createClient
